<template>
  <section>
    <b-card>
      <template #header>
        <div class="d-flex align-items-center">
          <span class="text-primary">Clienti </span>
          <b-breadcrumb class="ml-2">
            <b-breadcrumb-item>
              <feather-icon
                icon="HomeIcon"
                scale="1.25"
                shift-v="1.25"
              ></feather-icon>
            </b-breadcrumb-item>
            <b-breadcrumb-item active> Cerca cliente</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </template>

      <div class="mt-2">
        <h2 class="text-primary-light">Cerca per...</h2>

        <b-row class="my-2">
          <b-col md="4">
            <b-form-group label="Ragione Sociale">
              <b-form-input> </b-form-input>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group label="Cognome">
              <b-form-input> </b-form-input>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group label="Partita IVA">
              <b-form-input> </b-form-input>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group label="Codice Fiscale">
              <b-form-input> </b-form-input>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group label="Indirizzo E-mail">
              <b-form-input> </b-form-input>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group label="Telefono">
              <b-form-input> </b-form-input>
            </b-form-group>
          </b-col>
        </b-row>

        <div class="text-right">
          <b-button class="w-25" variant="primary"> Cerca </b-button>
        </div>
      </div>
    </b-card>
  </section>
</template>

<script>
import {
  BButton,
  BBreadcrumb,
  BBreadcrumbItem,
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
} from "bootstrap-vue";

export default {
  components: {
    BButton,
    BBreadcrumb,
    BBreadcrumbItem,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
  },
};
</script>
